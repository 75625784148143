<div class="d-flex flex-column justify-content-center align-items-center">
	<div class="image-container">
		<mat-icon svgIcon="account_circle" class="no-image" *ngIf="!image"></mat-icon>
		<div *ngIf="image" class="circle-image" [style.background-image]="'url(' + imageProfile + ')'"></div>
		<mat-progress-bar class="mt-2" *ngIf="progress" value={{progress}}></mat-progress-bar>
	</div>
	<div class="d-flex flex-column align-items-center icons mt-3 gap-1">
		<span>{{'IMAGEPROFILE.IMAGE' | translate}}</span>
		<div class="d-flex justify-content-center align-items-center gap-3">
			<!-- editar o agregar avatar -->
			<div class="edit-icon d-flex pointer">
				<input class="pointer" type="file" accept="image/*" (change)="onFileSelected($event)" />
				<nb-icon icon="edit" status="primary" *ngIf="userData.mail != '' || userData.mobile != ''"></nb-icon>
			</div>
			<!-- tomar foto -->
			<div *ngIf="!isIOS()" class="pointer">
				<nb-icon icon="camera-outline" size="medium" status="primary" (click)="tomarFoto()"></nb-icon>
			</div>
			<div class="pointer" *ngIf="image">
				<nb-icon icon="flip-outline" size="medium" status="primary"
					(click)="rotateProfileImage()"></nb-icon>
			</div>
			<!-- Eliminar -->
			<div class="pointer" *ngIf="image">
				<nb-icon icon="trash" size="medium" status="danger" (click)="deleteProfileImage()"></nb-icon>
			</div>
		</div>
	</div>
</div>
