import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/core/models/users/user.models';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { UsersService } from 'src/app/core/services/users';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalMicrofonoAudioComponent } from 'src/app/shared/components/modal-microfono-audio/modal-microfono-audio.component';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { isPlatformBrowser } from '@angular/common';


@Component({
    selector: 'app-image-profile',
    templateUrl: './image-profile.component.html',
    styleUrls: ['./image-profile.component.scss'],
    providers: [ImagenPipe],
    standalone: false
})
export class ImageProfileComponent implements OnInit {

  @Input() image:string|null;
  public imageProfile:string = ''
  public progress: number = 0
  private _idUser:number;
	public userData: any;
	@Input() readOnlyMode: boolean = false;

  constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
    private userServices: UsersService,
    private loginService: LoginService,
    private translateService: TranslateService,
    private imagePipe: ImagenPipe,
    private toaster: ToasterService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
		this.userData = this.loginService.getUser();
    if(this.image)
      this.imageProfile = this.imagePipe.transform(this.image, 'userAvatar')

    this._idUser = this.loginService.getUser().idUser
  }

  onFileSelected(event){
    const file = event.target.files[0];

    if(!file) return

    this.userServices.updateOrDeleteImageProfile(this.image,file).subscribe((event: HttpEvent<any>) => {

      switch(event.type){
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100)
          break
        case HttpEventType.Response:
          setTimeout(() => {
            this.progress = 0
            this.image = event.body.data
            this.imageProfile = this.imagePipe.transform(this.image, 'userAvatar')
            let user: User = this.loginService.getUser()
            user.pictureUser = this.image
            this.loginService.setUserLocalStorage(user)

            this.toaster.success(this.translateService.instant('IMAGEPROFILE.ADDIMAGEOK'))
          }, 500);
          break
      }
    }, err => { this.toaster.error(this.translateService.instant('IMAGEPROFILE.ADDIMAGEKO')) })
  }

		// Función para detectar iOS
	isIOS(): boolean {
		if (isPlatformBrowser(this.platformId)) {
			const ua = navigator.userAgent;
			return (
				/iPad|iPhone|iPod/.test(ua) ||
				(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
			);
		}
		return false;
	}

  deleteProfileImage(){
    this.userServices.updateOrDeleteImageProfile(this.image,'').subscribe((event: HttpEvent<any>) => {
      switch(event.type){
        case HttpEventType.Response:
          this.toaster.success(this.translateService.instant('IMAGEPROFILE.REMOVEIMAGEOK'))
          this.image = ''
          this.progress = 0
          let user: User = this.loginService.getUser()
          user.pictureUser = this.image
          this.loginService.setUserLocalStorage(user)
          break;
      }
    }, err => { this.toaster.error(this.translateService.instant('IMAGEPROFILE.REMOVEIMAGEKO')) })
  }

  tomarFoto() {
    let fileDevice: File;
    const modalRef = this.modalService.open(ModalMicrofonoAudioComponent, {
      scrollable: true,
      windowClass: MODAL_DIALOG_TYPES.W30,
    });
    modalRef.componentInstance.soloFoto = true;
    modalRef.result.then((res) => {
      this.userServices.updateOrDeleteImageProfile(this.image, res.file).subscribe((event: HttpEvent<any>) => {
        switch(event.type){
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100)
            break
          case HttpEventType.Response:
            setTimeout(() => {
              this.progress = 0
              this.image = event.body.data
              this.imageProfile = this.imagePipe.transform(this.image, 'userAvatar')
              let user: User = this.loginService.getUser()
              user.pictureUser = this.image
              this.loginService.setUserLocalStorage(user)

              this.toaster.success(this.translateService.instant('IMAGEPROFILE.ADDIMAGEOK'))
            }, 500);
            break
        }
      }, err => { this.toaster.error(this.translateService.instant('IMAGEPROFILE.ADDIMAGEKO')) })
    });
  }

  rotateProfileImage(){}

}
