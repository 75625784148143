import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Utils
import { LocalStorage } from 'src/app/core/utils';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';

// Models
import { UserRegisterModel, UserProfileValidationModel, UserStudentParentModel,
         UserParentModel, UserAuthorModel, UserCenterModel, UserEmployeerModel } from 'src/app/core/models/users';

import { CenterModel } from 'src/app/core/models/masters';

// Services
import { TranslateService } from '@ngx-translate/core';
import { AlertService, TitleService } from 'src/app/core/services/shared';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';
import { MastersService } from 'src/app/core/services/masters';
import { ModalStudentCentersComponent } from './modal-student-centers/modal-student-centers.component';

// Mapper
import { UsersAuthorsMapperService, UsersRegisterMapperService, UsersParentsMapperService, UsersCentersMapperService,
         UsersEmployeersMapperService, UsersStudentsParentsMapperService, UsersProfileValidationMapperService,
         CentersMapperService } from 'src/app/core/services/mapper';
import { User } from 'src/app/core/models/users/user.models';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';

@Component({
    selector: 'app-user-data',
    templateUrl: './user-data.component.html',
    styleUrls: ['./user-data.component.scss'],
    standalone: false
})
export class UserDataComponent implements OnInit {

  public formularioDatosGenerales: UntypedFormGroup;
  public formularioDatosPadreEstudiante: UntypedFormGroup;
  public formularioDatosCentroEstudiante: UntypedFormGroup;
  public formularioDatosHijoPadre: UntypedFormGroup;

  validationMessages = {
    firstName: [],
    surname: [],
    mail: [],
    birthdate: [],
    mobile: [],
    bic: [],
    iban: [],
    creditCard: [],
    expirationDateCard: [],
    cvv: []
  };

  verPestanaDatos = true;
  verPestanaPadres = false;
  verPestanaHijos = false;
  verPestanaCentros = false;
  verPestanaProfesores = false;

  pestanaDatosActiva = true;
  pestanaPadresActiva = false;
  pestanaHijosActiva = false;
  pestanaCentrosActiva = false;
  pestanaProfesoresActiva = false;

  verDetallePadreEstudiante = false;
  verDetalleHijoPadre = false;
  verDetalleCentroEstudiante = false;

  userData: User;

  usersParentData: UserParentModel;
  usersAuthorData: UserAuthorModel;
  usersCenterData: UserCenterModel;
  usersEmployeerData: UserEmployeerModel;

  usersProfileValidationData: UserProfileValidationModel[];

  parentsData: UserStudentParentModel[] = [];
  parentSelected = false;
  parentValidated = false;

  sonsData: UserRegisterModel[] = [];
  sonData: UserRegisterModel;
  sonValidated = false;

  centersData: CenterModel[] = [];
  centerData: CenterModel;
  centerValidated = false;

  genres: any[] = [];
  centers: CenterModel[] = [];
  enterprises: any[] = [];

  mensajeEnvioValidacion: string;
  mensajeEnvioValidado: string;

  public loading = false;

  constructor(private formBuild: UntypedFormBuilder,
              public translateService: TranslateService,
              private titleService: TitleService,
              private alertService: AlertService,
              private localStorage: LocalStorage,
              private modalService: NgbModal,
              public loginService: LoginService,
              public usersService: UsersService,
              private mastersService: MastersService,
              private usersRegisterMapperService: UsersRegisterMapperService,
              private usersAuthorsMapperService: UsersAuthorsMapperService,
              private usersParentsMapperService: UsersParentsMapperService,
              private usersCentersMapperService: UsersCentersMapperService,
              private usersEmployeersMapperService: UsersEmployeersMapperService,
              private centersMapperService: CentersMapperService,
              private usersStudentsParentsMapperService: UsersStudentsParentsMapperService,
              private usersProfileValidationMapperService: UsersProfileValidationMapperService) {

  }

  ngOnInit(): void {

    // Obtengo los maestros
    this.obtenerDatosMaestros();

    // Lanzo las traducciones
    this.traducirOpciones();

    // Creo los formularios
    this.crearFormularios();

    // Y obtengo la informacion del usuario
    this.obtenerDatosUsuario();
  }

  private obtenerDatosMaestros() {

    this.mastersService.getAllGenders().subscribe(data => { this.genres = data; });
    this.mastersService.getAllCenters().subscribe(data  => { this.centers = data});
  }

  private crearFormularios() {
    // Datos generales
    this.crearFormularioDatosGenerales();
    this.crearFormularioDatosPadresEstudiante();
  }

  private traducirOpciones() {
    // Recupero el lenguaje
    const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
    this.translateService.use(lang);

    // Titulo pagina
    this.translateService.get('USERDATA.MISDATOS').subscribe((res: string)  => {
      this.titleService.setHTMLTitle(res);
      this.titleService.setBarTitle(res);
    });

    this.translateService.get('VALIDACIONES.ELNOMBREESOBLIGATORIO').subscribe((res: string) => {
        this.validationMessages.firstName.push({type: 'required', message: res});
    });

    this.translateService.get('VALIDACIONES.LOSAPELLIDOSSONOBLIGATORIOS').subscribe((res: string) => {
        this.validationMessages.surname.push({type: 'required', message: res});
    });

    this.translateService.get('VALIDACIONES.ELTELEFONOMOVILESOBLIGATORIO').subscribe((res: string) => {
      this.validationMessages.mobile.push({type: 'required', message: res});
    });

    this.translateService.get('VALIDACIONES.ELTELEFONOMOVILNOESVALIDO').subscribe((res: string) => {
      this.validationMessages.mobile.push({type: 'pattern', message: res});
    });

    if (this.loginService.esEstudiante()) {

      this.translateService.get('VALIDACIONES.LAFECHADENACIMIENTOESOBLIGATORIA').subscribe((res: string) => {
        this.validationMessages.birthdate.push({type: 'required', message: res});
      });
    }

    this.translateService.get('VALIDACIONES.LATARJETADECREDITONOESVALIDA').subscribe((res: string) => {
      this.validationMessages.creditCard.push({type: 'pattern', message: res});
    });

    this.translateService.get('VALIDACIONES.DEBETENER16CARACTERES').subscribe((res: string) => {
      this.validationMessages.creditCard.push({type: 'minlength', message: res});
    });

    this.translateService.get('VALIDACIONES.DEBETENER5CARACTERES').subscribe((res: string) => {
      this.validationMessages.expirationDateCard.push({type: 'minlength', message: res});
    });

    this.translateService.get('VALIDACIONES.DEBETENER3CARACTERES').subscribe((res: string) => {
      this.validationMessages.cvv.push({type: 'minlength', message: res});
    });
  }

  // Mostrar Aviso
  public mostrarMensajeAviso() {

    // Para poder hacer las comparaciones
   // this.userData.usersProfileValidation = this.usersProfileValidationData;

    const resp = this.usersService.checkIfUserDataAreRegistered(this.userData, this.loginService.getProfile());

    if (resp.result !== true) {
        this.alertService.info(this.translateService.instant(resp.mensaje),
                               AlertService.AlertServiceContextValues.UserData);

        this.pestanaDatosActiva = false;
        this.pestanaPadresActiva = false;
        this.pestanaHijosActiva = false;
        this.pestanaCentrosActiva = false;
        this.pestanaProfesoresActiva = false;

        // Y muestro la pestaña que corresponde
        switch (resp.tab) {
          case 'Datos':
            this.pestanaDatosActiva = true;
            break;
          case 'Padres':
            this.pestanaPadresActiva = true;
            this.pestanaHijosActiva = true;
            break;
          default:
            this.pestanaDatosActiva = true;
            break;
        }
    } else {
      this.alertService.clear();
    }
  }
  // Fin mostrar aviso

  // Pestanas
  public mostrarPestanas() {

    switch (this.loginService.getProfile()) {
      case 'Estudiante':

        // Dependiendo de si tiene la fecha de nacimiento informada muestro solo Datos o Datos - Padres - Centros - Profesores
        if (this.userData.birthdate !== null && this.userData.birthdate !== undefined) {
          this.verPestanaDatos = true;
          this.verPestanaPadres = true;
          this.verPestanaHijos = false;
          this.verPestanaCentros = true;
          this.verPestanaProfesores = true;
        } else {
          this.verPestanaDatos = true;
          this.verPestanaPadres = false;
          this.verPestanaHijos = false;
          this.verPestanaCentros = false;
          this.verPestanaProfesores = false;
        }

        break;
      case 'Centro':
        break;
      case 'Profesor':
        break;
      case 'Padre':
        this.verPestanaDatos = true;
        this.verPestanaHijos = true;

        break;
      case 'Empleador':
        break;
      case 'Autor':

        this.verPestanaDatos = true;
        this.verPestanaPadres = false;
        this.verPestanaCentros = false;
        this.verPestanaProfesores = false;

        break;
    }
  }

  public activarPestana(nombre: string) {

    // Desactivo todas
    this.pestanaDatosActiva = false;
    this.pestanaPadresActiva = false;
    this.pestanaHijosActiva = false;
    this.pestanaCentrosActiva = false;
    this.pestanaProfesoresActiva = false;

    switch (nombre) {
      case 'Datos':
        this.pestanaDatosActiva = true;
        break;
      case 'Padres':
        this.pestanaPadresActiva = true;
        break;
      case 'Hijos':
        this.pestanaHijosActiva = true;
        break;
      case 'Centros':
        this.pestanaCentrosActiva = true;
        break;
      case 'Profesores':
        this.pestanaProfesoresActiva = true;
        break;
    }
  }
  // Fin pestanas

  // Datos Generales
  private crearFormularioDatosGenerales() {

    // default data and form groups
    this.formularioDatosGenerales = this.formBuild.group({
      idUser: [0],
      firstName: ['', Validators.required],
      surname: ['', Validators.required],
      pictureUser: [''],
      mail: [''],
      birthdate: [''],
      mobile: new UntypedFormControl('', [
        Validators.required,
        // tslint:disable-next-line: max-line-length
        Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})'),
      ]),
      idGenre: [''],
      idUserAuthor: [0],
      bic: [''],
      iban: [''],
      idUserParent: [0],
      creditCard: ['', Validators.minLength(16)],
      expirationDateCard: ['', Validators.minLength(4)],
      cvv: ['', Validators.minLength(3)],
      idUserCenter: [0],
      idCenter: [''],
      idUserEnterprise: [0],
      idEnterprise: [''],
      creationDate: [],
      creationDateString: []
    });
  }

  private cargoDatosFormularioDatosGenerales() {
    this.formularioDatosGenerales.controls.idUser.setValue(this.userData.idUser);
    this.formularioDatosGenerales.controls.pictureUser.setValue(this.userData.pictureUser);
    this.formularioDatosGenerales.controls.firstName.setValue(this.userData.firstName);
    this.formularioDatosGenerales.controls.surname.setValue(this.userData.surname);
    this.formularioDatosGenerales.controls.mail.setValue(this.userData.mail);
    this.formularioDatosGenerales.controls.birthdate.setValue(this.userData.birthdate);
    this.formularioDatosGenerales.controls.mobile.setValue(this.userData.mobile);
    this.formularioDatosGenerales.controls.idGenre.setValue(this.userData.idGenre);

    // Cargo mas datos - PADRE
    if (this.usersParentData != null) {
      this.formularioDatosGenerales.controls.idUserParent.setValue(this.usersParentData.idUserParent);
      this.formularioDatosGenerales.controls.creditCard.setValue(this.usersParentData.creditCard);
      this.formularioDatosGenerales.controls.expirationDateCard.setValue(this.usersParentData.expirationDateCard);
      this.formularioDatosGenerales.controls.cvv.setValue(this.usersParentData.cvv);
    }

    // Cargo mas datos - AUTOR
    if (this.usersAuthorData != null) {
      this.formularioDatosGenerales.controls.idUserAuthor.setValue(this.usersAuthorData.idUserAuthor);
      this.formularioDatosGenerales.controls.bic.setValue(this.usersAuthorData.bic);
      this.formularioDatosGenerales.controls.iban.setValue(this.usersAuthorData.iban);
    }

    // Cargo mas datos - CENTRO
    if (this.usersCenterData != null) {
      this.formularioDatosGenerales.controls.idUserCenter.setValue(this.usersCenterData.idUserCenter);
      this.formularioDatosGenerales.controls.idCenter.setValue(this.usersCenterData.idCenter);
    }

    // Cargo mas datos - EMPLEADOR
    if (this.usersEmployeerData != null) {
      this.formularioDatosGenerales.controls.idUserEnterprise.setValue(this.usersEmployeerData.idUserEnterprise);
      this.formularioDatosGenerales.controls.idEnterprise.setValue(this.usersEmployeerData.idEnterprise);
    }
  }

  private obtenerDatosUsuario() {

    this.usersService.getUserByIdUser(this.loginService.getUser().idUser)
                      .subscribe( resp => {
                        if (resp) {

                          // Mapeo los datos del usuario
                          //this.userData = this.usersRegisterMapperService.transform(resp.data.users.nodes[0]);
                          this.userData = resp

                          // // Mapeo los datos del usuario-padre
                          // if (resp.data.users.nodes[0].usersParents.length > 0) {
                          //   this.usersParentData = this.usersParentsMapperService.transform(resp.data.users.nodes[0].usersParents[0]);
                          // }

                          // // Mapeo los datos del usuario-autor
                          // if (resp.data.users.nodes[0].usersAuthors.length > 0) {
                          //   this.usersAuthorData = this.usersAuthorsMapperService.transform(resp.data.users.nodes[0].usersAuthors[0]);
                          // }

                          // // Mapeo los datos del usuario-centro
                          // if (resp.data.users.nodes[0].UsersCenters.length > 0) {
                          //   this.usersCenterData = this.usersCentersMapperService.transform(resp.data.users.nodes[0].UsersCenters[0]);
                          // }

                          // // Mapeo los datos del usuario-empleador
                          // if (resp.data.users.nodes[0].usersEnterprises.length > 0) {
                          //   // tslint:disable-next-line: max-line-length
                          //   this.usersEmployeerData = this.usersEmployeersMapperService.transform(resp.data.users.nodes[0].usersEnterprises[0]);
                          // }

                          // // Los de las validaciones del usuario (si las tiene)
                          // if (resp.data.users.nodes[0].usersProfileValidation.length > 0) {
                          //   // tslint:disable-next-line: max-line-length
                          //   this.usersProfileValidationData = this.usersProfileValidationMapperService.transform(resp.data.users.nodes[0].usersProfileValidation);
                          // }

                          // // Los de los padres del usuario (si los tiene)
                          // if (resp.data.users.nodes[0].usersStudentsParents.length > 0) {
                          //   // tslint:disable-next-line: max-line-length
                          //   this.parentsData = this.usersStudentsParentsMapperService.transform(resp.data.users.nodes[0].usersStudentsParents);

                          //   this.parentsData.forEach(parent => {

                          //     if (this.usersProfileValidationData !== undefined) {
                          //       // tslint:disable-next-line: max-line-length
                          //       const parentValidations = this.usersProfileValidationData.filter(x => x.idUserStudentParent === parent.idUserStudentParent);

                          //       if (parentValidations.filter(x => x.validationDate !== undefined && x.validationDate != null).length > 0) {
                          //         parent.validated = true;
                          //       } else {
                          //         parent.validated = false;
                          //       }
                          //     } else {
                          //       parent.validated = false;
                          //     }
                          //   });
                          // }

                          // // Los de los centros del estudiante (si los tiene)
                          // if (resp.data.users.nodes[0].usersStudentsCenters.length > 0) {

                          //   resp.data.users.nodes[0].usersStudentsCenters.forEach(centerData => {

                          //     if (centerData.center.length > 0) {
                          //       let center = centerData.center[0];

                          //       // tslint:disable-next-line: max-line-length
                          //       center.idUserStudentCenter = resp.data.users.nodes[0].usersStudentsCenters.find(x => x.idCenter === center.idCenter).idUserStudentCenter;

                          //       this.centersData.push(this.centersMapperService.transform(center));
                          //     }
                          //   });
                          // }

                          // Cargo los datos del formulario de datos generales
                          this.cargoDatosFormularioDatosGenerales();

                          // Muestro las pestanas en funcion de los datos
                          this.mostrarPestanas();

                          // Y el mensaje de aviso en funcion de si ha sido validado o no.
                          this.mostrarMensajeAviso();

                          this.cargarDatosHijosSiPadre();

                          this.cargarDatosCentroSiEstudiante();
                        } else {
                          // tslint:disable-next-line: max-line-length
                          this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.UserData);

                          // tslint:disable-next-line: max-line-length
                          //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos generales - Datos de usuario`, 'ERROR');
                        }
    }, (error) => {
          // tslint:disable-next-line: max-line-length
          this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.UserData);
    });
  }



  public grabarDatosGenerales() {

    if (this.formularioDatosGenerales.invalid) {
      return;
    }

    // Recupero los datos del formulario
    this.loading = true;

    const campos = this.formularioDatosGenerales.value;

    // Y restauramos los datos del usuario en base al formulario
    // Los campos que no van a ser modificados iran a undefined y no se actualizaran
    this.userData = this.usersRegisterMapperService.transform(campos);
    // El telefono no se actualiza
    this.userData.mobile = null;

    this.usersService.updateUser(this.userData)
                      .subscribe((resp: any) => {

                        // tslint:disable-next-line: max-line-length
                        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar datos generales - Datos de usuario`, 'OK');

                        // En funcion del perfil sigo grabado o no
                        switch (this.loginService.getProfile()) {
                          case 'Estudiante':
                            // Y el mensaje de aviso en funcion de si ha sido validado o no.
                            this.mostrarMensajeAviso();

                            return;
                          case 'Padre':
                            // Grabo los datos del padre (tarjeta bancaria)
                            const userParent = this.usersParentsMapperService.transform(campos);
                            userParent.idUser = this.userData.idUser;
                            break;
                          case 'Profesor':
                            // No grabo nada mas
                            break;
                          case 'Autor':
                            // Grabo los datos del autor (BIC/SWIFT-IBAN)
                            const userAuthor = this.usersAuthorsMapperService.transform(campos);
                            userAuthor.idUser = this.userData.idUser;
                            break;
                          case 'Centro':
                            // Grabo los datos del centro al que corresponde
                            const userCenter = this.usersCentersMapperService.transform(campos);
                            userCenter.idUser = this.userData.idUser;
                            break;
                          case 'Empleador':
                            // Grabo los datos de la empresa a la que corresponde
                            const userEnterprise = this.usersEmployeersMapperService.transform(campos);
                            userEnterprise.idUser = this.userData.idUser;
                            break;
                          default:
                            break;
                      }
                    }, (error) => {
                      // tslint:disable-next-line: max-line-length
                      this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.UserData);

                      // tslint:disable-next-line: max-line-length
                      //this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar datos generales - Datos de usuario - ERROR: ${error}`, 'ERROR');
                    });

    this.loading = false;
  }

  // IMAGEN
  cambiarFoto() {
    // if (!this.loginService.esAutor()) {
    //   return;
    // }

    // this.usersService.addUserLog(this.loginService.getUser().idUser, `Abrir ventana modal - Cambiar foto - Editar curso`, 'OK');

    // const modalRef = this.modalService.open(ModalSubirImagenComponent,
    // {
    //   scrollable: true,
    //   windowClass: MODAL_DIALOG_TYPES.W50
    // });

    // modalRef.componentInstance.id = this.curso.idCourse;
    // modalRef.componentInstance.nombreCurso = this.curso.courseTittle;
    // modalRef.componentInstance.imagenCurso = this.curso.cpicture;
    // modalRef.componentInstance.tipo = 'cursos';

    // modalRef.result.then((result) => {
    //     // No hago nada
    //   }, (reason) => {
    // });
  }
  // FIN IMAGEN

  // Fin datos generales

  // Datos Padres Estudiantes
  private crearFormularioDatosPadresEstudiante() {

    if (this.loginService.getProfile() === 'Estudiante') {
      // default data and form groups
      this.formularioDatosPadreEstudiante = this.formBuild.group({
        idUserStudentParent: [0],
        idUser: [0],
        firstName: ['', Validators.required],
        surname: ['', Validators.required],
        mail: [''],
        birthdate: [''],
        mobile: new UntypedFormControl('', [
          Validators.required,
          // tslint:disable-next-line: max-line-length
          Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})'),
        ]),
        creationDate: [null],
        creationDateString: [null]
      });
    }
  }

  private resetearFormularioDatosPadreEstudiante() {
    this.formularioDatosPadreEstudiante.reset();

    this.formularioDatosPadreEstudiante.controls.idUserStudentParent.setValue(0);
    this.formularioDatosPadreEstudiante.controls.creationDateString.setValue(null);
    this.parentSelected = false;
  }

  private cargoDatosFormularioDatosPadre(parentData) {
    this.formularioDatosPadreEstudiante.controls.idUserStudentParent.setValue(parentData.idUserStudentParent);
    this.formularioDatosPadreEstudiante.controls.firstName.setValue(parentData.firstName);
    this.formularioDatosPadreEstudiante.controls.surname.setValue(parentData.surname);
    this.formularioDatosPadreEstudiante.controls.mail.setValue(parentData.mail);
    this.formularioDatosPadreEstudiante.controls.birthdate.setValue(parentData.birthdate);
    this.formularioDatosPadreEstudiante.controls.mobile.setValue(parentData.mobile);
    this.formularioDatosPadreEstudiante.controls.creationDate.setValue(parentData.creationDate);
    this.formularioDatosPadreEstudiante.controls.creationDateString.setValue(parentData.creationDateString);

    // Voy a ver si ha sido validado ya
    if (this.usersProfileValidationData != undefined) {
      const parentValidations = this.usersProfileValidationData.filter(x => x.idUserStudentParent === parentData.idUserStudentParent);

      if (parentValidations.filter(x => x.validationDate !== undefined && x.validationDate != null).length > 0) {
        this.parentValidated = true;
      } else {
        this.parentValidated = false;
      }
    } else {
      this.parentValidated = false;
    }
  }

  public cargarDatosPadre(idPadre) {

    this.verDetallePadreEstudiante = true;

    // Limpio la informacion previa
    this.resetearFormularioDatosPadreEstudiante();

    // Y cargo los datos
    if (idPadre !== undefined) {
      this.cargoDatosFormularioDatosPadre(this.parentsData.filter(x => x.idUserStudentParent === idPadre)[0]);

      if (this.usersProfileValidationData !== undefined) {
        const aviso = this.usersProfileValidationData.filter(x => x.idUserStudentParent === idPadre)[0];
        if (aviso !== undefined) {

          this.translateService.get('USERDATA.SELEENVIOUNAVISOPARATUVALIDACIONEL').subscribe((res: string) => {
            this.mensajeEnvioValidacion = `${res} ${aviso.sendDate}`;
          });

          if (aviso.validationDate !== null) {
            this.translateService.get('USERDATA.HAVALIDOELACCESOEL').subscribe((res: string) => {
              this.mensajeEnvioValidado = `${res} ${aviso.validationDate}`;
            });
          } else {
            this.mensajeEnvioValidado = '';
          }
        } else {
          this.mensajeEnvioValidacion = '';
          this.mensajeEnvioValidado = ''
        }

        this.parentSelected = true;
      } else {
        this.mensajeEnvioValidacion = '';
        this.mensajeEnvioValidado = ''
      }
    }
  }

  public eliminarPadre() {

    let idUserStudentParent = this.formularioDatosPadreEstudiante.value.idUserStudentParent;

    this.resetearFormularioDatosPadreEstudiante();
  }

  public reenviarAviso() {
    this.enviarAvisoAprobacionPadre(this.formularioDatosPadreEstudiante.controls.idUserStudentParent.value);
  }

  private enviarAvisoAprobacionPadre(idUserStudentParent) {

    let userProfileValidation = new UserProfileValidationModel();

    userProfileValidation.idUserProfileValidation = 0;
    userProfileValidation.idUser = this.userData.idUser;
    userProfileValidation.idUserStudentParent = idUserStudentParent;
    userProfileValidation.uuid = uuidv4();

    this.translateService.get('USERDATA.SMSVALIDARESTUDIANTEPADRE').subscribe((res: string) => {
      userProfileValidation.messageSms = res.replace('{1}', `${this.userData.firstName} ${this.userData.surname}`)
                                            .replace('{2}', `${environment.domainUrl}/validate-profile/${userProfileValidation.uuid}`);
    });

    userProfileValidation.attempNumbers = 0;
  }

  public grabarDatosPadreUsuario() {

    if (this.formularioDatosPadreEstudiante.invalid) {
      return;
    }

    // Recupero los datos del formulario
    this.loading = true;

    // Y restauramos los datos del usuario en base al formulario
    const idUser = this.userData.idUser;
    let parentData = this.usersStudentsParentsMapperService.transform(this.formularioDatosPadreEstudiante.value);
    parentData.idUser = idUser;
    this.loading = false;
  }

  // Fin datos padres estudiantes

  // Datos centros estudiantes
  private cargarDatosCentroSiEstudiante() {
    if (this.loginService.getProfile() === 'Estudiante') {

      this.crearFormularioCentrosEstudiante();

      // Y cargo el primer centro de la lista
      if (this.centersData.length > 0) {
        this.cargarDatosCentro(this.centersData[0].idCenter);
      }
    }
  }

  private crearFormularioCentrosEstudiante() {

    if (this.loginService.getProfile() === 'Estudiante') {
      this.formularioDatosCentroEstudiante = this.formBuild.group({
        pictureUser: [''],
        idCenter: [''],
        centerName: [''],
        centerAddress: [''],
        centerCountry: [''],
        centerRegion: [''],
        centerMail: [''],
        centerPhone: [''],
        centerUrl: ['']
      });
    }
  }

  private cargoDatosFormularioDatosCentro() {

    this.formularioDatosCentroEstudiante.controls.idCenter.setValue(this.centerData.idCenter);
    this.formularioDatosCentroEstudiante.controls.centerName.setValue(this.centerData.centerName);
    this.formularioDatosCentroEstudiante.controls.centerAddress.setValue(this.centerData.centerAddress);
    this.formularioDatosCentroEstudiante.controls.centerCountry.setValue(this.centerData.centerCountry);
    this.formularioDatosCentroEstudiante.controls.centerRegion.setValue(this.centerData.centerRegion);
    this.formularioDatosCentroEstudiante.controls.centerMail.setValue(this.centerData.centerMail);
    this.formularioDatosCentroEstudiante.controls.centerPhone.setValue(this.centerData.centerPhone);
    this.formularioDatosCentroEstudiante.controls.centerUrl.setValue(this.centerData.centerUrl);
  }

  public cargarDatosCentro(idCentro) {

    this.verDetalleCentroEstudiante = true;

    // Y cargo los datos
    if (idCentro !== undefined) {
      this.centerData = this.centersData.find(x => x.idCenter === idCentro);

      this.cargoDatosFormularioDatosCentro();
    }
  }

  public asignarCentroEstudiante() {
    const modalRef = this.modalService.open(ModalStudentCentersComponent,
    {
      scrollable: true,
      windowClass: MODAL_DIALOG_TYPES.W80
    });

    // Lo registro en el log
    //this.usersService.addUserLog(this.loginService.getUser().idUser, `Asignar centro estudiante - Datos de usuario`, 'OK');

    // Le paso los centros que ya tiene para que no salgan en el filtro
    modalRef.componentInstance.centersAssigned = this.centersData;

    modalRef.result.then((result) => {

      switch (result.message) {
        case 'Centro asignado':
          // tslint:disable-next-line: max-line-length
          this.alertService.success(this.translateService.instant('CURSOS.ELCENTROSEHAASIGNADOCORRECTAMENTE'), AlertService.AlertServiceContextValues.ModalCurso);

          // Y lo asigno al objeto que almacena los centros
          this.centersData.push(result.center);
          break;
      }
    }, (reason) => {
    });
  }

  public desasignarCentroEstudiante() {
  }
  // Fin Datos centros estudiantes

  // Datos hijos padre
  private cargarDatosHijosSiPadre() {
    // Ademas, si el perfil es el del padre obtengo los hijos que tenga
    if (this.loginService.getProfile() === 'Padre') {
      this.crearFormularioHijosPadre();
    }
  }

  private crearFormularioHijosPadre() {

    this.formularioDatosHijoPadre = this.formBuild.group({
      idUer: [''],
      firstName: [''],
      surname: [''],
      pictureUser: [''],
      mail: [''],
      birthdate: [''],
      mobile: ['']
    });
  }

  private cargoDatosFormularioDatosHijo() {
    this.formularioDatosHijoPadre.controls.pictureUser.setValue(this.sonData.pictureUser);
    this.formularioDatosHijoPadre.controls.firstName.setValue(this.sonData.firstName);
    this.formularioDatosHijoPadre.controls.surname.setValue(this.sonData.surname);
    this.formularioDatosHijoPadre.controls.mail.setValue(this.sonData.mail);
    this.formularioDatosHijoPadre.controls.birthdate.setValue(this.sonData.birthdate);
    this.formularioDatosHijoPadre.controls.mobile.setValue(this.sonData.mobile);

    this.sonValidated = this.sonData.sonValidated;
  }

  public cargarDatosHijo(idHijo) {

    this.verDetalleHijoPadre = true;

    // Y cargo los datos
    if (idHijo !== undefined) {
      this.sonData = this.sonsData.find(x => x.idUser === idHijo);

      this.cargoDatosFormularioDatosHijo();
    }
  }
  // Fin Datos hijos padre
}
