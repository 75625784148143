import { User } from '../../../core/models/users/user.models';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// Utils
import { LocalStorage } from 'src/app/core/utils';

// Models
import { UserProfileValidationModel, UserAuthorModel, UserSettingsProfileModel } from 'src/app/core/models/users';

// Services
import { TranslateService } from '@ngx-translate/core';
import { AlertService, TitleService } from 'src/app/core/services/shared';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';

// Mapper
import { UsersAuthorsMapperService, UsersRegisterMapperService, UsersProfileValidationMapperService } from 'src/app/core/services/mapper';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';

@Component({
    selector: 'app-user-data',
    templateUrl: './user-data-autor.component.html',
    styleUrls: ['../user-data-general/user-data-general.component.scss'],
    standalone: false
})
export class UserDataAutorComponent implements OnInit {

  public formularioDatos: UntypedFormGroup;

  validationMessages = {
    bic: [],
    iban: []
  };

  userData: User;

  usersAuthorData: UserAuthorModel;

  usersProfileValidationData: UserProfileValidationModel[];

  usersSettingsProfileData: UserSettingsProfileModel[];

  public loading = false;

  constructor(private formBuild: UntypedFormBuilder,
              public translateService: TranslateService,
              private titleService: TitleService,
              private alertService: AlertService,
              private localStorage: LocalStorage,
              public loginService: LoginService,
              private usersService: UsersService,
              private usersRegisterMapperService: UsersRegisterMapperService,
              private usersAuthorsMapperService: UsersAuthorsMapperService,
              private usersProfileValidationMapperService: UsersProfileValidationMapperService,
              private activeModal: NgbActiveModal,
              public userService: UsersService
              ) {

  }

  ngOnInit(): void {

    // Lanzo las traducciones
    this.traducirOpciones();

    // Cargo las validaciones del formulario
    this.cargarValidacionesFormularios();

    // Cargo los formularios
    this.crearFormularios();

    // Y obtengo la informacion del usuario
    this.obtenerDatosUsuario();
  }

  private traducirOpciones() {
    // Recupero el lenguaje
    const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
    this.translateService.use(lang);

    // Titulo pagina
    this.translateService.get('USERDATA.CONFIGURACIONAUTOR').subscribe((res: string)  => {
      this.titleService.setHTMLTitle(res);
      this.titleService.setBarTitle(res);
    });
  }

  private cargarValidacionesFormularios() {

    this.translateService.get('VALIDACIONES.DEBETENER24CARACTERES').subscribe((res: string) => {
      this.validationMessages.iban.push({type: 'minlength', message: res});
    });
  }

  public mostrarMensajeAviso() {

    // Para poder hacer las comparaciones
    //this.userData.usersProfileValidation = this.usersProfileValidationData;

    const resp = this.usersService.checkIfUserDataAreRegistered(this.userData, 'autor');

    if (resp.result !== true) {
        this.alertService.info(this.translateService.instant(resp.mensaje),
                               AlertService.AlertServiceContextValues.UserData);
    } else {
      this.alertService.clear();
    }
  }

  private crearFormularios() {

    // default data and form groups
    this.formularioDatos = this.formBuild.group({
      idUser: [this.loginService.getUser().idUser],
      idUserAuthor: [0],
      bic: [''],
      iban: [''],
    });
  }

  private cargoDatosformularioDatos() {

    if (this.usersAuthorData != null) {
      this.formularioDatos.controls.idUser.setValue(this.userData.idUser);
      this.formularioDatos.controls.idUserAuthor.setValue(this.usersAuthorData.idUserAuthor);
      this.formularioDatos.controls.bic.setValue(this.usersAuthorData.bic);
      this.formularioDatos.controls.iban.setValue(this.usersAuthorData.iban);
    }
  }

  private registrarUserSettingProfile() {

    // Y si era la primera vez que grababa algo siendo estudiante lo registro en users_settings_profile
    if (this.usersSettingsProfileData.length === 0 ||
        this.usersSettingsProfileData.filter(x => x.profile === 'autor').length === 0) {

      let userSettingProfile = new UserSettingsProfileModel();

      userSettingProfile.idUserSettingProfile = 0;
      userSettingProfile.idUser = this.userData.idUser;
      userSettingProfile.profile = 'autor';
    }
  }

  private obtenerDatosUsuario() {

    this.loading = true;

    this.usersService.getUserByIdUser(this.loginService.getUser().idUser)
                      .subscribe( resp => {
                        if (resp) {

                          // tslint:disable-next-line: max-line-length
                          //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos generales - Datos de usuario autor`, 'OK');

                          // // Mapeo los datos del usuario
                          // this.userData = this.usersRegisterMapperService.transform(resp.data.users.nodes[0]);

                          // // Mapeo los datos del usuario-autor
                          // if (resp.data.users.nodes[0].usersAuthors.length > 0) {
                          //   this.usersAuthorData = this.usersAuthorsMapperService.transform(resp.data.users.nodes[0].usersAuthors[0]);
                          // }

                          // // Los de las validaciones del usuario (si las tiene)
                          // if (resp.data.users.nodes[0].usersProfileValidation.length > 0) {
                          //   // tslint:disable-next-line: max-line-length
                          //   this.usersProfileValidationData = this.usersProfileValidationMapperService.transform(resp.data.users.nodes[0].usersProfileValidation);
                          // }

                          // Cargo los user settings profile para ver que menus van en gris
                          // this.usersSettingsProfileData = resp.data.users.nodes[0].usersSettingsProfiles;

                          if (this.usersSettingsProfileData === undefined) {
                            this.usersSettingsProfileData = [];
                          }

                          // Cargo los datos
                          this.cargoDatosformularioDatos();

                          // Y el mensaje de aviso en funcion de si ha sido validado o no.
                          this.mostrarMensajeAviso();

                          this.loading = false;
                        } else {
                          // tslint:disable-next-line: max-line-length
                          this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.UserData);

                          // tslint:disable-next-line: max-line-length
                          //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos generales - Datos de usuario autor`, 'ERROR');
                        }
    }, (error) => {
      this.loading = false;

      // tslint:disable-next-line: max-line-length
      this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.UserData);

      // tslint:disable-next-line: max-line-length
      //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos generales - Datos de usuario autor - ERROR: ${error}`, 'ERROR');
    });
  }

  public grabarDatos() {

    if (this.formularioDatos.invalid) {
      return;
    }

    // Recupero los datos del formulario
    this.loading = true;

    const campos = this.formularioDatos.value;

    // Y restauramos los datos del usuario en base al formulario
    // Los campos que no van a ser modificados iran a undefined y no se actualizaran
    this.userData = this.usersRegisterMapperService.transform(campos);

    // El telefono no se actualiza
    this.userData.mobile = null;

    this.usersService.updateUser(this.userData)
                      .subscribe((resp: any) => {

                        // tslint:disable-next-line: max-line-length
                        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar datos generales - Datos de usuario autor`, 'OK');

                      // Grabo los datos del autor (BIC/SWIFT-IBAN)
                        this.usersAuthorData = this.usersAuthorsMapperService.transform(campos);
                        this.usersAuthorData.idUser = this.userData.idUser;
                    }, (error) => {
                      console.log(error);

                      this.loading = false;

                      // tslint:disable-next-line: max-line-length
                      this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.UserData);

                      // tslint:disable-next-line: max-line-length
                      //this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar datos generales - Datos de usuario autor - ERROR: ${error}`, 'ERROR');
                    });
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }
}
