import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserDataAutorComponent } from './user-data-autor/user-data-autor.component';
import { UserDataCentroComponent } from './user-data-centro/user-data-centro.component';
import { UserDataEmpleadorComponent } from './user-data-empleador/user-data-empleador.component';
import { UserDataEstudianteComponent } from './user-data-estudiante/user-data-estudiante.component';
import { UserDataGeneralComponent } from './user-data-general/user-data-general.component';
import { UserDataPadreComponent } from './user-data-padre/user-data-padre.component';
import { UserDataProfesorComponent } from './user-data-profesor/user-data-profesor.component';
import { UserDataEditorComponent } from './user-data-editor/user-data-editor.component';

const routes: Routes = [
    { path: 'general', component: UserDataGeneralComponent },
    { path: 'estudiante', component: UserDataEstudianteComponent },
    { path: 'padre', component: UserDataPadreComponent },
    { path: 'autor', component: UserDataAutorComponent },
    { path: 'editor', component: UserDataEditorComponent },
    { path: 'centro', component: UserDataCentroComponent },
    { path: 'profesor', component: UserDataProfesorComponent },
    { path: 'empleador', component: UserDataEmpleadorComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserDataRoutingModule { }
