import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserDataRoutingModule } from './user-data-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserDataComponent } from './user-data.component';
import { ModalCentersComponent } from './modal-centers';
import { ModalStudentCentersComponent } from './modal-student-centers';
import { UserDataAutorComponent } from './user-data-autor/user-data-autor.component';
import { UserDataCentroComponent } from './user-data-centro/user-data-centro.component';
import { UserDataEmpleadorComponent } from './user-data-empleador/user-data-empleador.component';
import { UserDataEstudianteComponent } from './user-data-estudiante/user-data-estudiante.component';
import { UserDataGeneralComponent } from './user-data-general/user-data-general.component';
import { UserDataPadreComponent } from './user-data-padre/user-data-padre.component';
import { UserDataProfesorComponent } from './user-data-profesor/user-data-profesor.component';
import { ImageProfileComponent } from './user-data-general/image-profile/image-profile.component';
import { ModalListCentersComponent } from './modal-list-centers/modal-list-centers.component';


import { NbDialogModule,NbButtonModule, NbCardModule, NbLayoutModule, NbProgressBarModule, NbActionsModule, NbInputModule, NbIconModule, NbSearchModule, NbToggleModule, NbSelectModule, NbDatepickerModule, NbTooltipModule, NbTabsetModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbMomentDateModule   } from '@nebular/moment';
import { UserDataEditorComponent } from './user-data-editor/user-data-editor.component';

@NgModule({
    declarations: [
        UserDataComponent,
        ModalCentersComponent,
        ModalStudentCentersComponent,
        ModalListCentersComponent,
        UserDataAutorComponent,
        UserDataCentroComponent,
        UserDataEmpleadorComponent,
        UserDataEstudianteComponent,
        UserDataGeneralComponent,
        UserDataPadreComponent,
        UserDataProfesorComponent,
        UserDataEditorComponent,
        ImageProfileComponent
    ],
    imports: [
        CommonModule,
        UserDataRoutingModule,
        SharedModule,
        NbEvaIconsModule,
        NbLayoutModule,
        NbButtonModule,
        NbCardModule,
				NbTabsetModule,
        NbProgressBarModule,
        NbActionsModule,
        NbInputModule,
        NbIconModule,
        NbSearchModule,
        NbToggleModule,
        NbSelectModule,
        NbDatepickerModule.forRoot(),
        NbDialogModule.forRoot({ closeOnBackdropClick: true, hasScroll: false }),
        NbMomentDateModule,
        NbTooltipModule
    ],
    exports: [
        UserDataComponent,
        ModalCentersComponent,
        ModalStudentCentersComponent,
        ModalListCentersComponent,
        UserDataAutorComponent,
        UserDataCentroComponent,
        UserDataEmpleadorComponent,
        UserDataEstudianteComponent,
        UserDataGeneralComponent,
        UserDataPadreComponent,
        UserDataProfesorComponent,
        UserDataProfesorComponent,
        ImageProfileComponent
    ]
})
export class UserDataModule { }
